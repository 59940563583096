import { useMemo } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import compact from "lodash/compact";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { DatasetGroup, MappedDropdownOption } from "common/components/charts/Investigate/types";

import { STATE_LOCATION_NAME } from "common/constants";
import {
  capitalizeFirstLetter,
  extractParentheticalText,
  humanize,
  titleize
} from "common/util/helpers";

import { HotjarSurveyLink, HotjarSurveyLinkProps } from "../../HotjarSurveyLink/HotjarSurveyLink";

export const InvestigateChartTitlesFallback = () => (
  <Stack gap={4}>
    <Stack>
      <Typography variant="h3">
        <Skeleton width="55%" />
      </Typography>
      <Typography variant="h5">
        <Skeleton width="25%" />
      </Typography>
    </Stack>
  </Stack>
);

export interface InvestigateChartTitlesProps {
  selectedDataset?: DatasetGroup;
  selectedOptions?: MappedDropdownOption[];
  groupTitle?: string | null;
  hideGroupTitle?: boolean;
  locationName?: string | null;
  stratificationTitle?: string | null;
  stratificationCategory?: string | null;
  subtitle?: string | null;
  title: string;
  appendToTitle?: string;
  surveyLinkProps?: HotjarSurveyLinkProps;
  titleAndStratificationSeparator?: string;
  date?: string;
}

// TODO: Write tests for this function, it's too complex and prone to regressions.
export const getChartTitle = ({
  date,
  groupTitle,
  hideGroupTitle = false,
  locationName,
  stratificationTitle,
  title,
  appendToTitle,
  selectedDataset,
  selectedOptions,
  titleAndStratificationSeparator = " by "
}: InvestigateChartTitlesProps) => {
  // Compose title based on chart state (selections)
  if (selectedDataset && selectedOptions) {
    let _title = [selectedDataset.statIdentifier?.name];
    if (selectedOptions.length === 1) {
      _title = [[..._title, first(selectedOptions)?.title].join(titleAndStratificationSeparator)];
    } else if (selectedOptions.length > 1) {
      const [firstSelection, secondSelection, ...otherSelections] = selectedOptions;
      const { group: firstSelectionGroup, title: firstSelectionTitle = "" } = firstSelection ?? {};
      const { title: secondSelectionTitle } = secondSelection ?? {};
      let selectionTitles = [
        `(${compact([titleize(humanize(firstSelectionGroup ?? "")), firstSelectionTitle]).join(
          ": "
        )})`
      ];
      selectionTitles.push(secondSelectionTitle ?? "");
      selectionTitles = [selectionTitles.join(titleAndStratificationSeparator)];
      if (otherSelections.length) {
        selectionTitles = [
          [...selectionTitles, ...otherSelections.map(({ title }) => title)].join(" — ")
        ];
      }
      _title = [..._title, ...selectionTitles];
    }
    if (date) {
      _title.push(date);
    }
    return _title.join(" ");
  }

  let _title = title;

  const titleParenthetical = extractParentheticalText(groupTitle);

  if (
    groupTitle &&
    _title !== groupTitle &&
    !_title.includes(groupTitle) &&
    !groupTitle.includes(title) &&
    hideGroupTitle === false
  ) {
    _title += ` (${groupTitle})`;
  } else if (isEmpty(title) && !isNil(groupTitle) && groupTitle !== "Investigation") {
    // We'll probably need to update this logic too when we work on removing the
    // Topic title from the chart titles
    _title += groupTitle;
  }

  // Handle situation where group title (from selected option) also contains the base title
  // In this case we only want to include the parenthetical (in most cases this will be the type of SI)
  if (
    groupTitle &&
    groupTitle?.includes(title) &&
    titleParenthetical &&
    !_title.includes(titleParenthetical)
  ) {
    _title += ` (${titleParenthetical as string})`;
  }

  if (stratificationTitle) {
    _title += ` ${
      _title.length > 0
        ? stratificationTitle.includes("by") || titleAndStratificationSeparator
          ? titleAndStratificationSeparator
          : "by"
        : ""
    } ${stratificationTitle}`;
  }
  if (locationName && !(title.includes(locationName) || title.includes(STATE_LOCATION_NAME))) {
    _title += ` in ${locationName}`;
  }
  if (date && !title.includes(date)) {
    _title += ` ${date}`;
  }
  if (appendToTitle && !title.includes(appendToTitle)) {
    _title += appendToTitle;
  }
  return _title;
};

const InvestigateChartTitles: React.FC<InvestigateChartTitlesProps> = ({
  subtitle,
  surveyLinkProps,
  ...props
}) => {
  const title = useMemo(() => getChartTitle(props), [props]);
  return (
    <Stack spacing={0.5}>
      <Typography variant="h4" data-testid={"InvestigationTitle"}>
        {title}
      </Typography>
      {subtitle && (
        <Stack direction="column" mt={1} gap={1}>
          <Typography component="p" variant="body2">
            {capitalizeFirstLetter(subtitle)}
          </Typography>
          {!isNil(surveyLinkProps) && <HotjarSurveyLink {...surveyLinkProps} />}
        </Stack>
      )}
    </Stack>
  );
};

export default InvestigateChartTitles;
